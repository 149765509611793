export default function OurTeam() {
  const teamMembers = [
    {
      name: "Sarvesh Hon (CEO)",
      role: "Android Developer",
      image: "assets/images/face1.jpg",
      socialMedia: [
        {
          iconclassName: "socicon-twitter socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-facebook socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-linkedin socicon",
          link: "https://mobiri.se/",
        },
      ],
    },
    {
      name: "Jayesh Deshmukh (CTO)",
      role: "Designer",
      image: "assets/images/face2.jpg",
      socialMedia: [
        {
          iconclassName: "socicon-twitter socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-facebook socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-linkedin socicon",
          link: "https://mobiri.se/",
        },
      ],
    },
    {
      name: "Kunal Deore (COO)",
      role: "Designer",
      image: "assets/images/face2.jpg",
      socialMedia: [
        {
          iconclassName: "socicon-twitter socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-facebook socicon",
          link: "https://mobiri.se/",
        },
        {
          iconclassName: "socicon-linkedin socicon",
          link: "https://mobiri.se/",
        },
      ],
    },
    // Add more team members here
  ];
  return (
    <>
      <section className="team1 cid-s5z3SVOGbh" id="team01-c">
        <div className="container-fluid">
          <div className="row justify-content-center pb-4">
            <div className="col-md-10 col-lg-6 col-12">
              <div className="align-wrapper align-center">
                <h5 className="main-sub-title mbr-fonts-style mb-3 display-4">
                  About InreachBit
                </h5>
              </div>

              <h4 className="mbr-section-title mbr-fonts-style align-center mb-3 display-2">
                <strong>
                  Expertise and collaboration for your technology needs
                </strong>
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <img
                src="assets/images/inreachbit-aboutus.jpg"
                alt="graphics-development"
                style={{
                  borderRadius: "20px",
                  height: "400px",
                  width: "400px",
                }}
              />
            </div>
            <div className="col-md-6">
              <h5 className="mbr-section-subtitle mbr-fonts-style  mb-3 display-7">
                <strong>Inreachbit</strong> is a leading provider of innovative
                IT solutions for businesses of all sizes. Our team of
                experienced professionals is dedicated to helping our clients
                achieve their goals through the use of cutting-edge technology.
                With a focus on collaboration and innovation, we work closely
                with our clients to understand their unique needs and deliver
                customized solutions that exceed their expectations.
              </h5>
              <h5 className="mbr-section-subtitle mbr-fonts-style  mb-3 display-7">
                We pride ourselves on our ability to stay ahead of the curve
                when it comes to the latest developments in technology. From
                cloud computing and data analytics to cybersecurity and software
                development, our expertise covers a wide range of areas. Our
                solutions are designed to help businesses improve efficiency,
                streamline processes, and drive growth.
              </h5>
              <h5 className="mbr-section-subtitle mbr-fonts-style  mb-3 display-7">
                At Inreachbit, we believe that technology should be a driver of
                progress, not a barrier. That's why we work tirelessly to
                provide our clients with the tools they need to succeed in
                today's digital world. Whether you're a small business just
                starting out or a large corporation looking to streamline
                operations, we have the expertise and experience to help you
                achieve your goals.
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
