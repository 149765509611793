import React from "react";

const ExperienceSection = () => {
  return (
    <section className="content1 cid-s5z6udHHZP" id="content01-f">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-2">
              <strong>Our Experience</strong>
            </h4>
            <p className="mbr-text mbr-fonts-style display-7 text-justify">
              With years of experience in both web and Android development, our
              team has honed its skills in creating powerful digital solutions.
              In web development, we have delivered numerous projects spanning
              various industries, crafting custom websites that are visually
              appealing, user-friendly, and optimized for performance. From
              responsive designs to seamless navigation, we prioritize creating
              engaging web experiences that drive conversions and enhance brand
              presence.
              <br />
              <br />
              In Android development, we have successfully created innovative
              and feature-rich applications tailored to diverse business needs.
              Our expertise in the Android platform enables us to develop
              intuitive user interfaces, seamless functionality, and efficient
              backend integration. Whether it's a standalone app or integration
              with existing systems, we are committed to delivering Android
              applications that provide exceptional user experiences and help
              businesses achieve their goals in the mobile space.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSection;
