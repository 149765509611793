import Footer from "../components/footer";
import HeaderServices from "../components/headerservices";
import Navbar from "../components/navbar";
export default function VideoEditing() {
  return (
    <>
      <Navbar />
      <HeaderServices
        title="Video Editing"
        info='"Elevating visual storytelling through expert video editing, creating compelling narratives that captivate audiences and leave a lasting impact."
        '
        buttonText="What we edit..."
      />
      <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-2 container-fluid text-center">
        <strong>What we build</strong>
      </h4>

      <Footer />
    </>
  );
}
