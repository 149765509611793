import React from "react";

export default function Header() {
  return (
    <section className="header1 cid-s5yZSRfeGK" id="header01-0">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
        width="1150"
        height="1150"
      >
        <defs>
          <path
            d="M591.7 245.6C618.95 316.15 651.66 392.09 601.95 465.7C552.23 539.31 399.63 500.01 330.83 500.01C253.43 500.01 172.9 542.43 119.05 505.87C93.07 488.24 77.27 453.64 71.67 402.08C66.16 351.48 51.8 302.24 29.22 256.61C19.23 236.4 34.81 267.91 27.68 253.49C-0.29 196.95 16.6 128.48 67.66 91.44C81.89 81.11 94.25 73.3 104.74 68.02C119.44 60.62 145.26 51.64 182.2 41.08C259.86 18.9 340.98 11.46 421.38 19.16C464.03 23.25 442.2 21.16 477.83 24.57C559.97 32.44 615.46 112.1 594.36 191.88C588.01 215.87 587.13 233.78 591.7 245.6Z"
            id="c1STRLUcV9"
          ></path>
        </defs>
        <g>
          <g>
            <g>
              <use
                xlinkHref="#c1STRLUcV9"
                opacity="1"
                fill="#92d7e4"
                fillOpacity="1"
              ></use>
            </g>
          </g>
        </g>
      </svg>
      <img className="image1" src="assets/images/phone.png" alt="Mobirise" />
      <img className="image2" src="assets/images/laptop.png" alt="Mobirise" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 align-center">
            <h2 className="mbr-section-subtitle mbr-fonts-style mb-3 display-4">
              CUSTOM SOFTWARE DEVELOPMENT
            </h2>
            <h4 className="mbr-section-title mbr-fonts-style mb-4 display-2">
              <strong>Turn Your Brilliant Idea Into a Business</strong>
              <hr
                style={{
                  height: "5px",
                  borderWidth: "0",
                  color: "gray",
                  width: "50px",
                  backgroundColor: "white",
                }}
              />
            </h4>
            <p className="mbr-text mbr-fonts-style mb-5 display-7">
              We are an IT services & product base company. Providing that helps
              with your IT needs. We build attractive mobile apps, websites, &
              more. for idea to grow your business in tech.
              <br />
            </p>
            <div className="mbr-section-btn mt-3">
              <a
                className="btn btn-lg btn-secondary display-4"
                href="#features06-3"
              >
                Get Started{" "}
                <span className="mbr-iconfont mbri-down mobi-mbri"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
