import React from "react";
import Navbar from "../components/navbar";
import HeaderAboutUs from "../components/headeraboutus";
import OurTeam from "../components/ourteam";
import Footer from "../components/footer";

const About = () => (
  <>
    <Navbar />
    <HeaderAboutUs />
    <OurTeam />
    <Footer />
  </>
);

export default About;
