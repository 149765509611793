import Footer from "../components/footer";
import HeaderServices from "../components/headerservices";
import Navbar from "../components/navbar";

export default function ApplicationDevelopment() {
  return (
    <>
      <Navbar />
      <HeaderServices
        title="Application Development"
        info='"Our experienced app developers utilize the latest technologies and best practices to create custom mobile and web applications that are both functional and visually appealing. We work closely with you to understand your requirements and develop solutions that align with your business goals.
        ."'
        buttonText="What we build.."
      />

      <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-2 container-fluid text-center">
        <strong>What we build</strong>
      </h4>

      <section className="features2 cid-s5z3CZ8bdU" id="features02-a">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col1 align-left my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        E-commerce Application
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Shop anytime, anywhere.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Fitness and Health
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Your personal fitness companion.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Food Delivery
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Delicious meals delivered to your doorstep.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Music and Entertainment
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Immerse yourself in a world of music and fun.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7 md-pb">
              <img
                src="assets/images/app.gif"
                alt="Mobirise"
                style={{ borderRadius: "20px" }}
              />
            </div>
            <div className="col-lg-4 col-md-7 col2 align-right my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Education and Learning
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Unlock your potential through knowledge.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Financial Management
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Take control of your finances with ease.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        News and Media
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Stay informed, stay ahead.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Productivity and Task Management
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Simplify your life, boost your productivity.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
