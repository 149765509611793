import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section className="features6 cid-s5z0evULgQ" id="features06-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pb-4">
            <div className="align-wrapper align-center">
              <h5 className="main-sub-title mbr-fonts-style mb-3 display-4">
                OUR SERVICES
              </h5>
            </div>
            <h4 className="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
              <strong>Our Features</strong>
            </h4>
          </div>
          <div className="col-12 col-md-6 md-pb col-lg-3">
            <div className="card">
              <div className="card-wrapper">
                <div className="img-wrapper">
                  <img src="assets/images/01.jpg" alt="Mobirise" />
                </div>
                <div className="card-box align-center">
                  <Link to="/application-development">
                    <h4 className="card-title mbr-fonts-style align-center mb-2 display-7">
                      <strong>App Development</strong>
                    </h4>
                    <p className="mbr-text mbr-semibold mbr-fonts-style mb-4 display-4">
                      DEVELOPMENT
                      <br />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 md-pb col-lg-3">
            <div className="card">
              <div className="card-wrapper">
                <div className="img-wrapper">
                  <img src="assets/images/02.jpg" alt="Mobirise" />
                </div>
                <div className="card-box align-center">
                  <Link to="/website-development">
                    <h4 className="card-title mbr-fonts-style align-center mb-2 display-7">
                      <strong>Website Development</strong>
                    </h4>
                    <p className="mbr-text mbr-semibold mbr-fonts-style mb-4 display-4">
                      DEVELOPMENT
                      <br />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 md-pb col-lg-3">
            <div className="card">
              <div className="card-wrapper">
                <div className="img-wrapper">
                  <img src="assets/images/03.jpg" alt="Mobirise" />
                </div>
                <div className="card-box align-center">
                  <Link to="/graphics-logo-development">
                    <h4 className="card-title mbr-fonts-style align-center mb-2 display-7">
                      <strong>Graphics & Logo Design</strong>
                    </h4>
                    <p className="mbr-text mbr-semibold mbr-fonts-style mb-4 display-4">
                      IDEAS
                      <br />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 md-pb col-lg-3">
            <div className="card">
              <div className="card-wrapper">
                <div className="img-wrapper">
                  <img src="assets/images/04.jpg" alt="Mobirise" />
                </div>
                <div className="card-box align-center">
                  <Link to="/video-editing">
                    <h4 className="card-title mbr-fonts-style align-center mb-2 display-7">
                      <strong>Video Editing</strong>
                    </h4>
                    <p className="mbr-text mbr-semibold mbr-fonts-style mb-4 display-4">
                      DESIGN
                      <br />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
