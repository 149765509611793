export default function ContactMap() {
  return (
    <>
      <section className="map1 cid-s5z3iwNfiA" id="map01-6">
        <div>
          <div className="google-map">
            <iframe
              frameborder="0"
              style={{ border: "0" }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCy9r70T3NYf3PhvVflTo0_zdif2_IoIYs&amp;q=place_id:ChIJn6wOs6lZwokRLKy1iqRcoKw"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}
