import Footer from "../components/footer";
import HeaderServices from "../components/headerservices";
import Navbar from "../components/navbar";
export default function WebsiteDevelopment() {
  return (
    <>
      <Navbar />
      <HeaderServices
        title="Website Development"
        info='"Our team of skilled web developers is proficient in designing and developing responsive, SEO-friendly websites that effectively showcase your brand and engage your target audience. From e-commerce platforms to content-rich sites, we deliver solutions that are tailored to your specific needs."'
        buttonText="What we build.."
      />
      <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-2 container-fluid text-center">
        <strong>What we build</strong>
      </h4>

      <section className="features2 cid-s5z3CZ8bdU" id="features02-a">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col1 align-left my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        E-commerce Application
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Shop anytime, anywhere.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Blogging Website
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Share your thoughts and ideas with the world
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        News and Magazine
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Deliver the latest news and stories right to your
                        readers' fingertips.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Business
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Empower your business with an interactive and
                        mobile-responsive website.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7 md-pb">
              <img
                src="assets/images/web.gif"
                alt="Mobirise"
                style={{ borderRadius: "20px" }}
              />
            </div>
            <div className="col-lg-4 col-md-7 col2 align-right my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Education and learning
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Transform education with a mobile-friendly platform for
                        learning.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Real Estate
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Find your dream property with a user-friendly Android
                        website.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Health and Wellness
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Promote well-being with a mobile-optimized website for
                        health services
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        ERP System
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Get ERP System that your business needs.
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
