import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <section className="menu cid-s5yZTTo2DU" once="menu" id="menu1-1">
      <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
        <div className="container-fluid">
          <div className="navbar-brand">
            <span className="navbar-logo">
              <a href="https://mobirise.com/">
                <img src="/assets/images/logo.png" alt="Mobirise" />
              </a>
            </span>
            <span className="navbar-caption-wrap">
              <a
                className="navbar-caption text-black display-5"
                href="https://mobirise.com/"
              >
                InreachBit
              </a>
            </span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav nav-dropdown link"
              data-app-modern-menu="true"
            >
              <Link to="/">
                <li className="nav-item">
                  <a
                    className="nav-link link text-white display-7"
                    href="https://mobirise.com/"
                  >
                    Home
                  </a>
                </li>
              </Link>
              <Link to="/about">
                <li className="nav-item">
                  <a
                    className="nav-link link text-white display-7"
                    href="https://mobirise.com/"
                  >
                    About Us
                  </a>
                </li>
              </Link>
              <li className="nav-item dropdown">
                <a
                  className="nav-link link text-white dropdown-toggle display-7"
                  data-toggle="dropdown-submenu"
                  aria-expanded="true"
                >
                  Services
                </a>
                <div className="dropdown-menu">
                  <Link
                    to="/application-development"
                    className="text-white dropdown-item display-7"
                  >
                    App Development
                  </Link>
                  <Link
                    to="/website-development"
                    className="text-white dropdown-item display-7"
                  >
                    Website Development
                  </Link>
                  <Link
                    to="/graphics-logo-development"
                    className="text-white dropdown-item display-7"
                  >
                    Graphics Design
                  </Link>
                  <Link
                    to="/video-editing"
                    className="text-white dropdown-item display-7"
                  >
                    Video Editing Services
                  </Link>
                </div>
              </li>
            </ul>
            <div className="icons-menu">
              <a className="iconfont-wrapper" href="https://mobirise.com/">
                <span className="icon-bg"></span>
                <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
              </a>
              <a className="iconfont-wrapper" href="https://mobirise.com/">
                <span className="icon-bg"></span>
                <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
              </a>
            </div>
            
            <Link to="/contact">
              <div className="navbar-buttons mbr-section-btn">
                <a
                  className="btn btn-sm btn-info-outline display-4"
                  href="https://mobirise.com/"
                >
                  Contact us
                </a>
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </section>
  );
}
