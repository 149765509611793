export default function Features() {
  return (
    <section className="features1 cid-s5z3G3tRUQ" id="features01-b">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="card col-12 col-md-6 md-pb col-lg-3">
            <div className="card-wrapper">
              <span className="mbr-iconfont mobi-mbri-android mobi-mbri"></span>
              <div className="card-box">
                <h4 className="card-title mbr-fonts-style mb-2 display-5">
                  <strong>App Development</strong>
                </h4>
                <h5 className="card-text mbr-fonts-style display-4">
                  "Transforming ideas into powerful and user-friendly mobile
                  applications to drive business growth and enhance user
                  experiences."
                </h5>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6 md-pb col-lg-3">
            <div className="card-wrapper">
              <span className="mbr-iconfont mobi-mbri-globe mobi-mbri"></span>
              <div className="card-box">
                <h4 className="card-title mbr-fonts-style mb-2 display-5">
                  <strong>Website Development</strong>
                </h4>
                <h5 className="card-text mbr-fonts-style display-4">
                  "Crafting dynamic and engaging websites that seamlessly blend
                  cutting-edge design with robust functionality, empowering
                  businesses to thrive online."
                </h5>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6 md-pb col-lg-3">
            <div className="card-wrapper">
              <span className="mbr-iconfont mbri-change-style mobi-mbri"></span>
              <div className="card-box">
                <h4 className="card-title mbr-fonts-style mb-2 display-5">
                  <strong>Graphics & Logo Design</strong>
                </h4>
                <h5 className="card-text mbr-fonts-style display-4">
                  "Bringing brands to life through visually captivating graphics
                  and logo designs that leave a lasting impression and
                  effectively communicate your unique identity."
                </h5>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6 col-lg-3">
            <div className="card-wrapper">
              <span
                className="mbr-iconfont mbri-video-play

mobi-mbri"
              ></span>
              <div className="card-box">
                <h4 className="card-title mbr-fonts-style mb-2 display-5">
                  <strong>Video Editing</strong>
                </h4>
                <h5 className="card-text mbr-fonts-style display-4">
                  "Elevating visual storytelling through expert video editing,
                  creating compelling narratives that captivate audiences and
                  leave a lasting impact."
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
