import React from "react";
import Header from "../components/header";
import Navbar from "../components/navbar";
import { Headercontactus } from "../components/headercontactus";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/footer";
import ContactMap from "../components/contactMap";
export default function Contact() {
  const [error, updateError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    ageOfBusiness: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedFormData = {
      name: formData.name.trim(),
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      ageOfBusiness: formData.ageOfBusiness.trim(),
      message: formData.message.trim(),
    };
    console.log(trimmedFormData);
    // Check if any form fields are empty
    if (!trimmedFormData.name) {
      toast.error("Name field cannot be empty");
      return;
    } else if (!trimmedFormData.email) {
      toast.error("Email field cannot be empty");
      return;
    } else if (!trimmedFormData.phone) {
      toast.error("Phone field cannot be empty");
      return;
    } else if (!trimmedFormData.ageOfBusiness) {
      toast.error("Age of Business field cannot be empty");
      return;
    } else if (!trimmedFormData.message) {
      toast.error("Please tell us about your Business");
      return;
    } else {
      if (!trimmedFormData.name) {
        toast.error("Name field cannot be empty");
        return;
      } else if (!trimmedFormData.email) {
        toast.error("Email field cannot be empty");
        return;
      } else if (!trimmedFormData.phone) {
        toast.error("Phone field cannot be empty");
        return;
      } else if (!trimmedFormData.ageOfBusiness) {
        toast.error("Age of Business field cannot be empty");
        return;
      } else if (!trimmedFormData.message) {
        toast.error("Please tell us about your Business");
        return;
      } else {
        const xhr = new XMLHttpRequest();
        const url = "https://inreachbit.com/send-data.php";

        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              const response = JSON.parse(xhr.responseText);
              // Handle the response from the API
              // ...
              toast.success("Thank you.!");
            } else {
              console.error("Error occurred. Status code:", xhr.status);
              console.error("Response:", xhr.responseText);
              toast.error("An error occurred while submitting the form");
            }
          }
        };
        const data = JSON.stringify({
          name: trimmedFormData.name,
          email: trimmedFormData.email,
          phone: trimmedFormData.phone,
          ageOfBusiness: trimmedFormData.ageOfBusiness,
          message: trimmedFormData.message,
        });

        xhr.send(data);
      }
    }

    // Perform form submission logic here
  };

  return (
    <>
      {" "}
      <div>
        <Navbar />
        <Headercontactus />
        <section className="form2 cid-s5z3jEzwKt" id="form02-7">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 md-pb">
                <div className="card col-12">
                  <div className="card-wrapper">
                    <a
                      className="iconfont-wrapper"
                      href="https://mobiri.se/"
                      target="_blank"
                    >
                      <span className="icon-bg icon1"></span>
                      <span className="p-2 mbr-iconfont mobi-mbri-map-pin mobi-mbri"></span>
                    </a>
                    <div className="card-box my-auto">
                      <h3 className="card-title mbr-fonts-style display-7">
                        <strong>Office Address</strong>
                      </h3>

                      <h5 className="card-text mbr-fonts-style display-7">
                        CS No 25/2, P NO 11 VITTHAL NAGAR, YEOLA R-12003 NASHIK,
                        NASHIK, MAHARASHTRA - 423401, INDIA
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12">
                  <div className="card-wrapper">
                    <a
                      className="iconfont-wrapper"
                      href="https://mobiri.se/"
                      target="_blank"
                    >
                      <span className="icon-bg icon2"></span>
                      <span className="p-2 mbr-iconfont mobi-mbri-letter mobi-mbri"></span>
                    </a>
                    <div className="card-box my-auto">
                      <h3 className="card-title mbr-fonts-style display-7">
                        <strong>Office Email</strong>
                      </h3>
                      <h5 className="card-text mbr-fonts-style display-7">
                        inreachbit@gmail.com
                        <br />
                        contact@inreachbit.com
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12">
                  <div className="card-wrapper">
                    <a
                      className="iconfont-wrapper"
                      href="https://mobiri.se/"
                      target="_blank"
                    >
                      <span className="icon-bg icon3"></span>
                      <span className="p-2 mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                    </a>
                    <div className="card-box my-auto">
                      <h3 className="card-title mbr-fonts-style display-7">
                        <strong>Office Phone</strong>
                      </h3>
                      <h5 className="card-text mbr-fonts-style display-7">
                        +91 96999 03090
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-7 mx-auto mbr-form"
                data-form-type="formoid"
              >
                <div className="row justify-content-center pb-4">
                  <div className="col-md-12 col-lg-12 col-12">
                    <div className="align-wrapper align-left">
                      <h5 className="main-sub-title mbr-fonts-style mb-3 display-4">
                        CONTACTS
                      </h5>
                    </div>

                    <h4 className="mbr-section-title mbr-fonts-style align-left mb-3 display-2">
                      Get in Touch
                    </h4>

                    <h5 className="mbr-section-subtitle mbr-fonts-style align-left mb-2 display-7">
                      Thank you for taking the time to visit our "Contact Us"
                      page. We look forward to hearing from you and serving you
                      better.
                    </h5>
                  </div>
                </div>

                <form
                  className="mbr-form form-with-styler"
                  onSubmit={handleSubmit}
                >
                  <div className="dragArea form-row">
                    <div
                      className="col-lg-6 col-md-12 col-sm-12 form-group"
                      data-for="name"
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        data-form-field="name"
                        className="form-control display-7"
                        id="name-form02-7"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div
                      className="col-lg-6 col-md-12 col-sm-12 form-group"
                      data-for="email"
                    >
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Id
                  "
                        className="form-control display-7"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input
                        placeholder="Phone"
                        className="form-control display-7"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div
                      className="col-lg-6 col-md-12 col-sm-12 form-group"
                      data-for="email"
                    >
                      <select
                        className="form-control display-7"
                        value={formData.ageOfBusiness}
                        name="ageOfBusiness"
                        onChange={handleInputChange}
                      >
                        <option>Age of your Business</option>
                        <option value="Startup">Startup</option>
                        <option value="1-3 Years">1-3 Years</option>
                        <option value="4-5 Years">4-5 Years</option>
                        <option value="5-10 Years">5-10 Years</option>
                        <option value="10+ Years">10+ Years</option>
                      </select>
                    </div>
                    <div
                      data-for="message"
                      className="col-lg-12 col-md-12 col-sm-12 form-group"
                    >
                      <textarea
                        name="message"
                        placeholder="Tell us about your Business"
                        data-form-field="message"
                        className="form-control display-7"
                        id="message-form02-7"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>

                    <div className="col-auto">
                      <button
                        type="submit"
                        className="btn btn-secondary-outline display-7"
                      >
                        Get in Touch
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactMap />
      <Footer />
    </>
  );
}
