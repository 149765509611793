import Footer from "../components/footer";
import HeaderServices from "../components/headerservices";
import Navbar from "../components/navbar";
export default function GraphicsLogoDevelopment() {
  return (
    <>
      <Navbar />
      <HeaderServices
        title="Graphics & Logo Design
        "
        info='"Bringing brands to life through visually captivating graphics and logo designs that leave a lasting impression and effectively communicate your unique identity."
        '
        buttonText="What we design.."
      />
      <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-2 container-fluid text-center">
        <strong>Graphics Design</strong>
      </h4>
      <section className="features2 cid-s5z3CZ8bdU" id="features02-a">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col1 align-left my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        All type of Graphics
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Versatile Graphic Design Services
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Brochure Design
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Engaging Brochure Design Services
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Social Media Graphics
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Dynamic Social Media Graphics
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <span className="mbr-iconfont mobi-mbri-right mobi-mbri"></span>
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Illustration Solutions
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Creative Illustration Solutions
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7 md-pb">
              <img
                src="assets/images/graphics-development.gif"
                alt="graphics-development"
                style={{ borderRadius: "20px" }}
              />
            </div>
            <div className="col-lg-4 col-md-7 col2 align-right my-auto">
              <div className="row justify-content-center">
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Logo Design
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Distinctive Logo Creation Expertise
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Poster Design
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Eye-catching Poster Design Solutions
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Packing Design
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Strategic Packaging Design Expertise
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
                <div className="card col-12 col-md-12 col-lg-12 md-pb">
                  <div className="card-wrapper">
                    <div className="card-box">
                      <h4 className="card-title mbr-fonts-style mb-2 display-5">
                        Brand Design
                      </h4>
                      <h5 className="card-text mbr-fonts-style display-4">
                        Strategic Branding Solutions
                      </h5>
                    </div>
                    <span className="mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
