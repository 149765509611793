// Home.js
import React from "react";
import Header from "../components/header";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Services from "../components/services";
import Features from "../components/features";
import ExperienceSection from "../components/experience";

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <Services />
      <Features />
      <ExperienceSection />
      <Footer />
    </>
  );
}
