import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <section className="contacts1 cid-s5z3hf8pRx" id="contacts01-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 img-col pb-5 align-center">
              <img src="assets/images/logo.png" alt="Mobirise" />

              <h3 className="title mbr-fonts-style display-5">
                <strong>InreachBit</strong>
              </h3>
            </div>
            <div className="card col-12 col-md-6 md-pb col-lg-4">
              <div className="card-wrapper">
                <a
                  className="iconfont-wrapper"
                  href="https://mobiri.se/"
                  target="_blank"
                >
                  <span className="icon-bg icon1"></span>
                  <span className="p-2 mbr-iconfont mobi-mbri-map-pin mobi-mbri"></span>
                </a>
                <div className="card-box my-auto">
                  <h5 className="card-text mbr-fonts-style display-7">
                    Nashik, Maharashtra
                  </h5>
                </div>
              </div>
            </div>
            <div className="card col-12 col-md-6 md-pb col-lg-4">
              <div className="card-wrapper">
                <a
                  className="iconfont-wrapper"
                  href="https://mobiri.se/"
                  target="_blank"
                >
                  <span className="icon-bg icon2"></span>
                  <span className="p-2 mbr-iconfont mobi-mbri-letter mobi-mbri"></span>
                </a>
                <div className="card-box my-auto">
                  <h5 className="card-text mbr-fonts-style display-7">
                    contact@inreachbit.com
                  </h5>
                </div>
              </div>
            </div>
            <div className="card col-12 col-md-6 md-pb col-lg-4">
              <div className="card-wrapper">
                <a
                  className="iconfont-wrapper"
                  href="https://mobiri.se/"
                  target="_blank"
                >
                  <span className="icon-bg icon3"></span>
                  <span className="p-2 mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                </a>
                <div className="card-box my-auto">
                  <h5 className="card-text mbr-fonts-style display-7">
                    +91 96999 03090
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="footer1 cid-s5z3hWjWSQ"
        once="footers"
        id="footer01-5"
      >
        <div className="container">
          <div className="media-container-row align-center mbr-white">
            <div className="row row-links">
              {/* <ul className="foot-menu">
                <Link to="/">
                  <li className="foot-menu-item mbr-fonts-style display-7 text-white">
                    Home
                  </li>
                </Link>
                <Link to="/about">
                  <li className="foot-menu-item mbr-fonts-style display-7 text-white">
                    About Us
                  </li>
                </Link>
                <li className="foot-menu-item mbr-fonts-style display-7">
                  Services
                </li>
                <li className="foot-menu-item mbr-fonts-style display-7">
                  Schedule Meeting
                </li>
                <Link to="/contact">
                  <li className="foot-menu-item mbr-fonts-style display-7 text-white">
                    Contact Us
                  </li>
                </Link>
              </ul> */}
            </div>

            <div className="row row-copirayt">
              <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-4">
                © Copyright 2023 Inreachbit - All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
