// App.js
import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Contact from "./pages/contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationDevelopment from "./pages/application";
import WebsiteDevelopment from "./pages/website";
import GraphicsLogoDevelopment from "./pages/graphics";
import VideoEditing from "./pages/video";
import About from "./pages/about";

export default function App() {
  return (
    <>
      {" "}
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/application-development"
          element={<ApplicationDevelopment />}
        />
        <Route path="/website-development" element={<WebsiteDevelopment />} />
        <Route
          path="/graphics-logo-development"
          element={<GraphicsLogoDevelopment />}
        />
        <Route path="/video-editing" element={<VideoEditing />} />
      </Routes>
    </>
  );
}
